import Typography from '@components/Typography/Typography'
import { Box } from '@material-ui/core'
import * as S from './NewOpportunities.style'
import { useState } from 'react'
import { useExpertWaitlistSubcribeMutation, WaitlistSubscribeErrorType } from '@generated/graphql'
import { validateEmail } from '@modules/utils'
import { publicRuntimeConfig } from '../../../../config/config'

const NewOpportunities: React.FC = () => {
  const userid = publicRuntimeConfig.NEXT_WAITLIST_EXPERT_ID

  const [email, setEmail] = useState<string | null>(null)
  const [emailSubmitted, setEmailSubmitted] = useState<boolean>(false)
  const [validEmail, setValidEmail] = useState<boolean>(true)
  const [error, setError] = useState<string>()

  const [expertWaitlistSubcribeMutation] = useExpertWaitlistSubcribeMutation()

  const onSubmit = async () => {
    if (!email) {
      setValidEmail(false)
      return
    }
    if (email && !validateEmail(email)) {
      setValidEmail(false)
      return
    }

    const { data, errors } = await expertWaitlistSubcribeMutation({
      variables: {
        input: { expertId: userid!, subscriberEmail: email },
      },
    })

    if (errors) {
      setError('There was an error. Please try later')
      return
    }

    if (
      !errors &&
      (data?.expertWaitlistSubcribe.success ||
        data?.expertWaitlistSubcribe.error === WaitlistSubscribeErrorType.AlreadySubscribed)
    ) {
      setEmailSubmitted(true)
    }
    setValidEmail(true)
  }
  return (
    <S.Container>
      <S.Content>
        <Box>
          <Typography
            responsiveVariant={{ xs: 'BUTTON_DESKTOP', sm: 'BUTTON_MOBILE', md: 'MOBILE_H600' }}
            weight="semibold"
          >
            Stay in the loop
          </Typography>
        </Box>
        <S.DescriptionContainer>
          <Typography
            responsiveVariant={{ xs: 'H300', sm: 'H200', md: 'BUTTON_MOBILE' }}
            weight="regular"
          >
            Get informed of new opportunities and live events.
          </Typography>
        </S.DescriptionContainer>
        {!emailSubmitted && (
          <>
            <S.EmailContainer>
              <S.TextField
                variant="filled"
                value={email}
                placeholder={'Email'}
                onChange={(e) => setEmail(e.target.value)}
              />
            </S.EmailContainer>
            {!validEmail && (
              <Box textAlign="center" color="red">
                Please, enter a valid email
              </Box>
            )}
            <S.ButtonContainer>
              <S.SubmitButton size="medium" variant="secondary" onClick={onSubmit}>
                <Typography
                  responsiveVariant={{ xs: 'BUTTON_MOBILE', sm: 'H200', md: 'BUTTON_MOBILE' }}
                  weight="semibold"
                >
                  Submit
                </Typography>
              </S.SubmitButton>
            </S.ButtonContainer>
          </>
        )}
        {emailSubmitted && (
          <Box>
            <Typography
              responsiveVariant={{ xs: 'BODY_SMALL', md: 'BUTTON_MOBILE' }}
              weight="regular"
            >
              Thank you! We will reach out soon
            </Typography>
          </Box>
        )}
        {error && (
          <Box textAlign="center" color="red">
            {error}
          </Box>
        )}
      </S.Content>
    </S.Container>
  )
}

export default NewOpportunities
