import styled, { css } from 'styled-components'
import ButtonLink from '@components/ButtonLink/ButtonLink'
import { Image } from 'cloudinary-react'

const Gradient = css`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, black, transparent 50%);
    border-radius: 16px;
  }
`

export const Card = styled.div<{ gradient?: boolean }>`
  position: relative;
  height: 100%;
  margin-right: 24px;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-right: 0px;
  }
  ${({ gradient }) =>
    gradient &&
    css`
      ${Gradient}
    `}
`
export const CardImage = styled(Image)`
  width: 100%;
  border-radius: 16px;
  display: block;
`

export const FormContentContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 auto;
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
`

export const CardContentContainer = styled.div`
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  z-index: 1;
  &:hover {
    cursor: pointer;
  }
`

export const VideoActionButton = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 7%;
  z-index: 1;
  text-align: center;
`

export const Date = styled.div`
  opacity: 0.8;
`
export const Description = styled.div`
  margin-top: 12px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 12px;
  }
`
export const Expert = styled.div`
  margin-top: 10px;
  color: #59b9ff;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 10px;
  }
`
export const Action = styled.div`
  margin-top: 20px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 8px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 20px;
  }
`

export const Button = styled(ButtonLink)``

export const DesktopView = styled.div`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    display: block;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`
export const MobileView = styled.div`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: block;
  }
`

export const CardContent = styled.div`
  padding-bottom: 7%;
  margin: 0 auto;
  text-align: center;
  ${({ theme }) => theme.breakpoints.between(850, 959)} {
    ${Date} {
      span {
        font-size: 14px;
        line-height: 16px;
      }
    }

    ${Description} {
      span {
        font-size: 32px;
        line-height: 50px;
      }
    }

    ${Expert} {
      span {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
`
