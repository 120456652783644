import styled, { css } from 'styled-components'
import { Container as MuiContainer } from '@material-ui/core'

export const Container = styled(MuiContainer)<{
  breakpoints: { [x: number]: { css: unknown } }
}>`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-right: 0px;
    margin-right: 0px;
    max-width: none;
  }

  ${(props) =>
    props.breakpoints &&
    Object.keys(props.breakpoints).map(
      (value) => css`
        ${({ theme }) => theme.breakpoints.up(Number(value))} {
          ${() => props.breakpoints[value].css};
        }
      `
    )}
`
export const BgFixed = styled.div`
  background: radial-gradient(
      52.62% 44.34% at 96.85% 50%,
      rgba(255, 0, 184, 0.25) 0%,
      rgba(255, 0, 168, 0) 100%
    ),
    radial-gradient(
        81.11% 28.69% at 4.2% 48.37%,
        rgba(117, 215, 240, 0.25) 0%,
        rgba(117, 215, 240, 0) 100%
      )
      #f4f4f4;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: -1;
`
export const MainContainer = styled.div``
export const Title = styled.div`
  margin: 0 auto;
  max-width: 850px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 80px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    padding-bottom: 50px;
  }
`
export const ContainerView = styled.div`
  width: 100%;
`

export const DesktopView = styled.div`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    display: block;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: none;
  }
`
export const MobileView = styled.div`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    display: none;
  }
  ${({ theme }) => theme.breakpoints.down('xs')} {
    display: block;
  }
`

export const MobileCardContainer = styled.div`
  padding: 10px 0 10px 0;
`
