import Typography from '@components/Typography/Typography'
import { CurrentPathTypeEnum } from '../../../analytics'
import { CardType } from '../../LandingPage.model'
import * as S from './LandingPageCard.style'
import { Transformation } from 'cloudinary-react'

import NewOpportunities from '../NewOpportunities/NewOpportunities'
import LandingVideoCard from '../LandingVideoCard/LandingVideoCard'
import { ButtonPropsLike, withLink, WithLinkProps } from '@components/ButtonLink/withLink'

export type Props = {
  card: CardType
  callToActionCard?: true
}

type CardWithLinkProps = {
  children: React.ReactNode
  callToAction: boolean
}

const CardWithLink = withLink(S.Card)

const CardWithCTA: React.FC<CardWithLinkProps & WithLinkProps<ButtonPropsLike>> = (props) => {
  const { callToAction, children, ...rest } = props
  if (!callToAction) return <>{children}</>
  return <CardWithLink {...rest}>{children}</CardWithLink>
}

const LandingPageCard: React.FC<Props> = ({ card, callToActionCard = false }) => {
  if (card.type == 'Video') {
    return (
      <S.Card>
        <LandingVideoCard card={card}></LandingVideoCard>
        <S.CardContentContainer>
          <S.VideoActionButton>
            {card.link ? (
              <S.Button
                size="medium"
                variant="primary"
                href={card.link!}
                analyticsClickEvent={{
                  name: card.label!,
                  currentPathType: CurrentPathTypeEnum.LandingPage,
                }}
              >
                <Typography
                  responsiveVariant={{ xs: 'BUTTON_MOBILE', sm: 'H200', md: 'BUTTON_MOBILE' }}
                  weight="semibold"
                >
                  {card.label}
                </Typography>
              </S.Button>
            ) : (
              <Typography
                responsiveVariant={{ xs: 'BUTTON_MOBILE', sm: 'H200', md: 'BUTTON_MOBILE' }}
                weight="semibold"
              >
                {card.label}
              </Typography>
            )}
          </S.VideoActionButton>
        </S.CardContentContainer>
      </S.Card>
    )
  }

  if (card.type == 'Form') {
    return (
      <S.Card>
        <S.CardImage
          cloud_name={process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}
          publicId={card.publicId}
          secure
        >
          <Transformation width={504} heigth={630} crop="fill" quality="auto" fetchFormat="auto" />
        </S.CardImage>
        <S.FormContentContainer>
          <NewOpportunities />
        </S.FormContentContainer>
      </S.Card>
    )
  }
  return (
    <CardWithCTA
      callToAction={callToActionCard && !!card.link}
      href={card.link!}
      target={card.linkTarget}
      analyticsClickEvent={{
        name: card.label!,
        currentPathType: CurrentPathTypeEnum.LandingPage,
      }}
    >
      <S.Card gradient={true}>
        <S.CardImage
          cloud_name={process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}
          publicId={card.publicId}
          secure
        >
          <Transformation width={504} heigth={630} crop="fill" quality="auto" fetchFormat="auto" />
        </S.CardImage>
        <S.CardContentContainer>
          <S.CardContent>
            <S.Date>
              <Typography
                responsiveVariant={{ xs: 'H300', sm: 'NOTE2', md: 'H200', lg: 'H300' }}
                color="white"
                weight="regular"
              >
                {card.date}
              </Typography>
            </S.Date>
            <S.Description>
              <Typography
                responsiveVariant={{
                  xs: 'MOBILE_H600',
                  sm: 'BUTTON_MOBILE',
                  md: 'LANDING_32',
                  xl: 'MOBILE_H600',
                }}
                color="white"
                weight="semibold"
              >
                {card.description}
              </Typography>
            </S.Description>
            <S.Expert>
              <Typography
                responsiveVariant={{
                  xs: 'H300',
                  sm: 'BODY_SMALL',
                  md: 'APPLICATION_TITLE_OVERLAY_SMALL',
                  lg: 'H300',
                }}
                weight="regular"
              >
                {card.expert}
              </Typography>
            </S.Expert>
            <S.Action>
              <S.Button
                size="medium"
                variant="primary"
                href={card.link!}
                target={card.linkTarget}
                analyticsClickEvent={{
                  name: card.label!,
                  currentPathType: CurrentPathTypeEnum.LandingPage,
                }}
              >
                <Typography
                  responsiveVariant={{ xs: 'BUTTON_MOBILE', sm: 'H200', md: 'BUTTON_MOBILE' }}
                  weight="semibold"
                >
                  {card.label}
                </Typography>
              </S.Button>
            </S.Action>
          </S.CardContent>
        </S.CardContentContainer>
      </S.Card>
    </CardWithCTA>
  )
}

export default LandingPageCard
