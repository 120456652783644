import styled from 'styled-components'
import { TextField as MuiTextField } from '@material-ui/core'
import Button from '@components/Button/Button'

export const Container = styled.div`
  height: 100%;
`

export const Content = styled.div`
  height: 100%;
  padding: 40px 30px 10px 30px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding-top: 20px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 40px;
  }
`
export const TextField = styled(MuiTextField)`
  width: 100%;
`
export const SubmitButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.color.purple};
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.purple};
  &:active,
  &:hover {
    background: ${({ theme }) => theme.color.cultured10};
    background-position: unset;
    box-shadow: none;
    color: ${({ theme }) => theme.color.purple};
  }
`

export const DescriptionContainer = styled.div`
  margin-top: 17px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 8px;
  }

  ${({ theme }) => theme.breakpoints.up(760)} {
    margin-top: 15px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 21px;
  }
`
export const ButtonContainer = styled.div`
  margin-top: 19px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 8px;
  }
  ${({ theme }) => theme.breakpoints.up(760)} {
    margin-top: 15px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 19px;
  }
`
export const EmailContainer = styled.div`
  margin-top: 19px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 0px;
  }
  ${({ theme }) => theme.breakpoints.up(760)} {
    margin-top: 15px;
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 19px;
  }
`
