/**
 * Media related to Landing Page
 */

export type CardVideo = {
  id: number
  type: 'Video'
  publicId: string
  link?: string
  label: string
}

export type Card = {
  id: number
  type: 'Card'
  publicId: string
  date: string
  description: string
  expert: string
  link?: string
  label: string
  linkTarget?: string
}

export type CardForm = {
  id: number
  type: 'Form'
  publicId: string
}

export type CardType = CardVideo | Card | CardForm

export const Cards: CardType[] = [
  {
    id: 1,
    publicId: 'Landing/Homepage_Video_rbxcbh',
    label: 'Explore Experts',
    type: 'Video',
  },
  // {
  //   id: 2,
  //   publicId: 'Landing/Jason_fmpa44',
  //   date: 'November 8, 2022',
  //   description: 'Acting Workshop',
  //   expert: 'with Jason Alexander',
  //   link: 'https://presents.protege.app/expert/jason',
  //   label: 'Get Details',
  //   linkTarget: '_blank',
  //   type: 'Card',
  // },
  // {
  //   id: 3,
  //   image: '/images/landing-dark-child.png',
  //   date: 'November 18th - 19th 2022',
  //   description: 'Songwriting Camp',
  //   expert: 'with Darkchild',
  //   link: 'https://presents.protege.app/expert/darkchild',
  //   label: '',
  //   type: 'Card',
  // },
  {
    id: 3,
    type: 'Card',
    date: 'February 10, 2022',
    description: 'Songwriting Session',
    expert: 'with Bebe Rexha',
    publicId: 'Landing/Bebe_wzjalx_hhznlc',
    link: 'https://www.protege.app/blog/protegeselection_beberexha',
    label: 'Read Story',
    linkTarget: '_blank',
  },
  {
    id: 4,
    type: 'Card',
    date: 'June 7th, 2022',
    description: 'Mangement Deal',
    expert: 'with H.E.R. & MBK',
    publicId: 'Landing/HER_kxgmwv_atyg8q',
    link: 'https://www.protege.app/blog/protegeselection_her',
    label: 'Read Story',
    linkTarget: '_blank',
  },
  {
    id: 5,
    type: 'Card',
    date: 'May 19th, 2022',
    description: 'Publishing Deal',
    expert: 'with Breland',
    publicId: 'Landing/Breland_b5oy0b_gk8wjb',
    link: 'https://www.protege.app/blog/protegeselection_breland',
    label: 'Read Story',
    linkTarget: '_blank',
  },
]
