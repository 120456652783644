import styled from 'styled-components'
import { IconButton as MuiIconButton } from '@material-ui/core'

export const Container = styled.div``
export const VideoContainer = styled.div`
  position: relative;
  cursor: pointer;
`

export const IconButton = styled(MuiIconButton)`
  position: absolute;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.purple};
  top: 10px;
  right: 10px;
  z-index: 1;

  &:hover {
    background-color: ${({ theme }) => theme.color.purple};
  }
`
