import styled from 'styled-components'
import { Video as CloudinaryVideo } from 'cloudinary-react'

export const Video = styled(CloudinaryVideo)`
  height: 100%;
  width: 100%;
  ${({ theme }) => theme.breakpoints.down('xs')} {
    object-fit: cover;
  }
  border-radius: 16px;
`

export const Container = styled.div``
