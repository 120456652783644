import Head from 'next/head'

import Typography from '@components/Typography/Typography'
import { Cards } from './LandingPage.model'
import dynamic from 'next/dynamic'

import * as S from './LadingPage.styles'
import { useTrackNavigationEvent, CurrentPathTypeEnum } from '@modules/analytics'
import LandingPageCard from './components/LandingPageCard/LandingPageCard'

const GAP = 24
const CARD_WIDTH = 520
const MARGIN = 100
const HALF_SLIDE = 0.5
const MIN_SLIDES = 4

const computeSlidesPerBreakpoint = () => {
  const result = {}
  for (let i = MIN_SLIDES; i < Cards.length + 1; i++) {
    if (i === Cards.length) {
      const breakpoint = MARGIN + (CARD_WIDTH + GAP) * i
      result[breakpoint] = {
        centeredSlides: false,
        slidesPerView: Cards.length,
        css: {
          marginRight: 'auto',
          marginLeft: 'auto',
          paddingRight: '24px',
          maxWidth: breakpoint,
        },
      }
      break
    }

    const breakpoint = MARGIN + (CARD_WIDTH + GAP) * i
    result[breakpoint] = {
      centeredSlides: false,
      slidesPerView: i + HALF_SLIDE,
      css: { marginRight: '0', marginLeft: 'auto' },
    }
  }

  return result
}
const LandingSwiper = dynamic(() => import('./components/LandingSwiper/LandingSwiper'), {
  ssr: false,
})

const LadingPage: React.FC = () => {
  useTrackNavigationEvent({ currentPathType: CurrentPathTypeEnum.LandingPage })
  return (
    <>
      <Head>
        <meta
          name="keywords"
          content={`Protégé, protege, Protege App, audition, application, opportunity`}
          key="keywords"
        />
      </Head>
      <S.BgFixed></S.BgFixed>
      <S.MainContainer>
        <S.Title>
          <Typography responsiveVariant={{ xs: 'MOBILE_H600', sm: 'H800' }} weight="medium">
            Be heard. Make connections. Get discovered.
          </Typography>
        </S.Title>
        <S.Container maxWidth="xl" breakpoints={computeSlidesPerBreakpoint()}>
          <S.ContainerView>
            <S.DesktopView>
              <LandingSwiper
                cards={Cards}
                breakpoints={computeSlidesPerBreakpoint()}
              ></LandingSwiper>
            </S.DesktopView>
            <S.MobileView>
              {Cards.map((c) => {
                return (
                  <S.MobileCardContainer key={c.id}>
                    <LandingPageCard card={c} callToActionCard={true} />
                  </S.MobileCardContainer>
                )
              })}
            </S.MobileView>
          </S.ContainerView>
        </S.Container>
      </S.MainContainer>
    </>
  )
}

export default LadingPage
