import { Cloudinary } from 'cloudinary-core'

/**
 * Cloudinary size limits on media uploads.
 */
export const CLOUDINARY_MAX_UPLOAD_SIZE = {
  video: {
    bytes: 2147483648,
    gigabytes: 2,
  },
  image: {
    bytes: 20971520,
    megabytes: 20,
  },
}

export type CloudinaryUploadWithPresetResponse = {
  batch_id: string
  public_id: string
  done?: boolean
}

export type CloudinaryUploadResponse = {
  bytes: number
  height: number
  original_filename: string
  public_id: string
  secure_url: string
  width: number
  duration?: number
}

export const cloudinary = new Cloudinary({
  cloud_name: process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME,
})

export const getExpertProfileImage = (
  expertProfileImagePublicId: string,
  options?: { [key: string]: string | boolean | number }
): string => {
  return cloudinary.url(expertProfileImagePublicId, {
    width: 300,
    height: 300,
    crop: 'fill',
    quality: '100',
    gravity: 'face',
    fetchFormat: 'auto',
    secure: true,
    ...options,
  })
}
export const getSVGIconUrl = (
  imagePublicId: string,
  options?: { [key: string]: string | boolean | number }
): string => {
  return cloudinary.url(imagePublicId, {
    width: 24,
    height: 24,
    flags: 'sanitize',
  })
}
