import { RefObject, useContext, useEffect, useRef } from 'react'
import SoundContext, { VideoRef, VideoState } from './SoundContext'

export type UseVideoCollectionInput = {
  videoRefs: RefObject<HTMLVideoElement>[]
  onPlayingVideoChanged?: (
    playingVideo: VideoState,
    videoRefs?: RefObject<HTMLVideoElement>[]
  ) => void
}

export type UseVideoCollectionOut = {
  videoRefs: RefObject<HTMLVideoElement>[]
  notifyPlayEvent: (ref: VideoRef) => void
  playingVideo: VideoState | null
}

export const useVideoCollection = ({
  videoRefs,
  onPlayingVideoChanged,
}: UseVideoCollectionInput): UseVideoCollectionOut => {
  const { playingVideo, notifyPlayEvent, registerVideo, unRegisterVideo } = useContext(SoundContext)

  useEffect(() => {
    videoRefs.forEach((ref) => {
      registerVideo(ref)
    })

    return () =>
      videoRefs.forEach((ref) => {
        unRegisterVideo(ref)
      })
  }, [videoRefs, registerVideo, unRegisterVideo])

  useEffect(() => {
    if (playingVideo && onPlayingVideoChanged) {
      onPlayingVideoChanged(playingVideo)
    }
  }, [playingVideo, onPlayingVideoChanged])

  return { videoRefs, notifyPlayEvent, playingVideo }
}

export type UseVideoInput = {
  onMuted?: (ref: RefObject<HTMLVideoElement>) => void
}

export type UseVideoOut = {
  videoRef: RefObject<HTMLVideoElement>
  notifyPlayEvent: (ref: VideoRef) => void
  playingVideo: VideoState | null
}

export const useVideo = ({ onMuted }: UseVideoInput = {}): UseVideoOut => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const { playingVideo, notifyPlayEvent, registerVideo, unRegisterVideo } = useContext(SoundContext)

  useEffect(() => {
    registerVideo(videoRef)
    return () => unRegisterVideo(videoRef)
  }, [videoRef, registerVideo, unRegisterVideo])

  useEffect(() => {
    if (playingVideo && playingVideo.ref !== videoRef && onMuted) {
      onMuted(videoRef)
    }
  }, [playingVideo, onMuted])

  return { videoRef, notifyPlayEvent, playingVideo }
}
