import LandingVideoPlayer from '../LandingVideoPlayer/LandingVideoPlayer'
import * as S from './LandingVideoCard.styles'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import { useVideo } from '@components/SoundProvider/useVideoCollection'
import { useEffect, useState } from 'react'
import { CardType } from '../../LandingPage.model'

export type Props = { card: CardType }

const LandingVideoCard: React.FC<Props> = ({ card }) => {
  const [muted, setMuted] = useState(true)
  const { videoRef, notifyPlayEvent } = useVideo({
    onMuted: () => {
      if (!muted) {
        setMuted(true)
        videoRef.current!.muted = true
      }
    },
  })

  const handleMuteUnmute = () => {
    setMuted((prevState) => !prevState)
    videoRef.current!.muted = !videoRef.current!.muted
    if (videoRef.current!.paused) {
      videoRef.current!.play()
    }
    notifyPlayEvent(videoRef)
  }

  useEffect(() => {
    if (videoRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio !== 1 && videoRef.current && !videoRef.current.muted) {
              videoRef.current.muted = true
              setMuted(true)
            }
          })
        },
        { threshold: 0.2 }
      )
      observer.observe(videoRef.current)
    }
  }, [videoRef])

  return (
    <S.Container>
      <S.VideoContainer aria-label="mute" onClick={handleMuteUnmute}>
        <LandingVideoPlayer ref={videoRef} publicId={card.publicId!} />
        <S.IconButton>{muted ? <VolumeOffIcon /> : <VolumeUpIcon />}</S.IconButton>
      </S.VideoContainer>
    </S.Container>
  )
}

export default LandingVideoCard
