import { forwardRef, memo } from 'react'

import * as Sentry from '@sentry/nextjs'
import { Transformation } from 'cloudinary-react'

import * as S from './LandingVideoPlayer.style'
import { cloudinary } from '../../../../config/cloudinary'

export type Props = {
  publicId: string
  width?: number
  height?: number
  autoPlay?: boolean
}

const handleError = (error) => {
  if (error instanceof Error) {
    Sentry.captureException(error)
  }
}

const LandingVideoPlayer = forwardRef<HTMLVideoElement, Props>(
  ({ publicId, autoPlay = true }, ref) => {
    const posterImageUrl = cloudinary.video_url(publicId, { format: 'jpg', startOffset: 2 })
    return (
      <S.Container>
        <S.Video
          innerRef={ref}
          publicId={publicId}
          cloud_name={process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME}
          onError={handleError}
          loop
          playsInline
          autoPlay={autoPlay}
          muted
          secure
          sourceTypes={['mp4']}
          preload="none"
          poster={posterImageUrl}
        >
          <Transformation width={504} heigth={630} crop="fill" quality="auto" fetchFormat="auto" />
        </S.Video>
      </S.Container>
    )
  }
)

export default memo(LandingVideoPlayer)
